<template>
  <div id="carousel">
    <van-notice-bar
      left-icon="volume-o"
      @replay="rePlay"
      :color="font_color"
      :background="bg_color"
      speed="120"
      :scrollable="lateral_play"
    >
      <van-swipe :touchable="false" @change="onChange" ref="ck" vertical class="notice-swipe" :show-indicators="false">
        <van-swipe-item ref="contentword" v-for="(item, index) in documents_list" :key="index">{{
          item
        }}</van-swipe-item>
      </van-swipe>
    </van-notice-bar>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        currentIndex: '', // 当前轮播的文案索引
        lateral_play: false, // true轮播展示，false平面展示（均为横向）
      };
    },

    props: {
      // 背景色
      bg_color: {
        type: String,
        default: 'none',
      },
      // 文案字体颜色
      font_color: {
        type: String,
        default: '#fff',
      },
      // 1轮播展示，2平面展示
      display_ways: {
        type: Number,
        default: 1,
      },
      // 是否开启横向轮播展示
      lateral: {
        type: Boolean,
        default: false,
      },
      // 文案列表，至少需要一条
      documents_list: {
        type: Array,
        default: () => {
          [];
        },
      },
    },
    methods: {
      /**
       * @description 垂直轮播切换触发
       * @param {string|number} index
       */
      onChange(index) {
        if (+this.display_ways === 1 && this.documents_list) {
          this.currentIndex = index;
          if (this.documents_list[index].length <= 25) {
            this.lateral_play = true;
          } else {
            this.lateral_play = false;
          }
        } else {
          return;
        }
      },
      /**
       * @description 每当滚动栏重新开始滚动时触发(横向)
       */
      rePlay() {
        if (+this.display_ways === 1 && this.documents_list) {
          let carouselCount;
          if (carouselCount) {
            clearTimeout(carouselCount);
          }
          if (this.lateral_play) {
            if (this.documents_list[+this.currentIndex].length <= 25) {
              carouselCount = setTimeout(() => {
                this.$refs.ck.next();
              }, 3000);
            } else {
              this.$refs.ck.next();
            }
            this.lateral_play = false;
          } else {
            this.lateral_play = true;
          }
        } else {
          return;
        }
      },
    },
    /**
     * @description 首次进入处理第一条信息的展示情况，
     * 平面 & 短文案
     * 平面 & 长文案
     * 滚动 & 短文案
     * 滚动 & 长文案
     */
    watch: {
      documents_list: {
        handler(val) {
          if (val) {
            if (val[0].length <= 25 && +this.display_ways === 1) {
              this.lateral_play = false;
              setTimeout(() => {
                this.$refs.ck.next();
              }, 3000);
            } else if (val[0].length > 25 && +this.display_ways === 1) {
              this.lateral_play = true;
            } else if (val[0].length <= 25 && +this.display_ways === 2) {
              return;
            } else {
              this.lateral_play = true;
            }
          }
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="less" scoped>
  #carousel {
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    margin-top: 0.1rem;
  }
  .van-notice-bar {
    padding-right: 0.3rem;
    font-size: 0.26rem;
  }
  .van-icon-volume-o {
    font-size: 0.28rem !important;
  }
  .notice-swipe {
    height: 0.4rem;
    line-height: 0.4rem;
  }
</style>
