<template>
  <div class="wrapper">
    <img class="banner" :class="{ 'detail-banner': showTab }" :src="ActivityInfo.banner_pic" alt="" />

    <Carousel
      v-if="carouselInfo"
      :display_ways="tip_switch"
      :font_color="showFontColor"
      :lateral="true"
      :documents_list="carouselInfo"
    />

    <!-- 自定义渲染区 :style="{ backgroundImage: `url(${activityInfo.background_pic})` }" -->
    <div class="content" :style="{ paddingBottom: carouselInfo ? '0.2rem' : '0.2rem' }">
      <div class="custom_item" v-for="item in renderList" :key="item.id">
        <!-- 图片区 -->
        <ul v-if="+item.type === 1" class="img_list">
          <li v-for="(img, index) in item.picture" :key="index">
            <div class="video-wrapper" v-if="img.resource_type && +img.resource_type === 1">
              <video
                x-webkit-airplay="true"
                x5-video-player-type="h5"
                x5-video-orientation="portraint"
                x5-video-player-fullscreen="true"
                webkit-playsinline
                playsinline
                :id="`video_${img.sort}`"
                :poster="img.pic_link"
                class="video"
                loop
                @click="changePlayVideo(img)"
              >
                <source :src="img.pic" type="video/mp4" />
              </video>
              <div class="control-wrap" @click="changePlayVideo(img)">
                <template>
                  <img
                    v-if="!img.isVideoPlay && !img.isVideoPlayed"
                    src="https://static1.kaixinyf.cn/img/lza61ef700480aa3540252155.png"
                    alt="快音"
                  />
                  <img
                    v-if="!img.isVideoPlay && img.isVideoPlayed"
                    src="https://static1.kaixinyf.cn/img/lza61ef700480aa3540252155.png"
                    alt="快音"
                  />
                </template>
              </div>
            </div>
            <Gallery v-else :info="img" :key="index" @click="handleClickImg(img)" />
          </li>
        </ul>
        <!-- 图片区结束 -->

        <!-- 规则文本区 -->
        <CustomRule
          v-else-if="+item.type == 2"
          :style="{ marginBottom: showCustomRule }"
          :title="item.text.title"
          :detail="item.text.detail"
          :is_jump="+item.text.is_jump"
          :jump_title="item.text.jump_title"
          :jump_link="item.text.jump_link"
        ></CustomRule>
        <!-- 规则文本区结束 -->

        <!-- 任务区 -->
        <TaskBlock v-else-if="+item.type == 8" :task="item" :handleShareActivity="() => {}"></TaskBlock>
        <!-- 任务区结束 -->

        <fortuneWheel ref="fortuneWheelRef" v-else-if="+item.type == 9" :prize="item"></fortuneWheel>
      </div>
    </div>
    <!-- 自定义渲染区结束 -->

    <!-- 投稿参考内容 -->
    <Title v-if="musicGuideInfo" title="投稿参考内容">
      <MusicGuideList :guide-list="musicGuideInfo.examples_music_list" />
    </Title>

    <!-- 登录用户才显示歌曲列表 showMusicList  -->
    <div v-if="showMusicList" class="music_list_box" :class="{ 'pb-music-button': showBottomBtn }">
      <!-- 列表title -->
      <ListTitle :music_show="2" :music_show_text="musicInfo.work.title"></ListTitle>
      <!-- 列表title结束 -->

      <!-- 音乐列表 -->
      <ActivityMusicListV2
        v-if="musicInfo"
        :activity_id="activity_id"
        :preview="preview"
        :music_show="musicInfo ? 1 : 2"
        :topic_id="+musicInfo.work.topic_id"
        :name="ActivityInfo.name"
        :musicListType="+musicInfo.work.type"
        :area_id="+musicInfo.id"
      ></ActivityMusicListV2>
      <!-- 音乐列表结束 -->
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Carousel from '../Carousel/index.vue';
  import Gallery from './Gallery.vue';
  import CustomRule from './CustomRule.vue';
  import Title from './Title.vue';
  import ListTitle from './ListTitle.vue';
  import ActivityMusicListV2 from './ActivityMusicListV2.vue';
  import TaskBlock from './task-block/index.vue';
  import fortuneWheel from '../fortune-wheel/index.vue';

  export default {
    name: 'Rule',
    components: {
      Carousel,
      Gallery,
      CustomRule,
      Title,
      ListTitle,
      ActivityMusicListV2,
      TaskBlock,
      fortuneWheel,
    },
    data() {
      return {
        preview: 0,
        activity_id: 1,
        tip_switch: null,
        renderList: [],
        buttonInfo: null,
        carouselInfo: null,
        musicGuideInfo: null,
        musicInfo: null,
      };
    },
    props: {
      showTab: {
        type: Boolean,
      },
      post: {
        type: Function,
      },
    },
    computed: {
      ...mapGetters('activity/activityTemplate', ['ActivityInfo']),
      showBottomBtn() {
        return this.buttonInfo && this.buttonInfo.button_type != 0;
      },
      showCustomRule() {
        if (this.carouselInfo) {
          if (this.showBottomBtn) {
            return '0rem';
          } else {
            return '0.8rem';
          }
        } else {
          return '0rem';
        }
      },
      showMusicList() {
        return this.musicInfo && this.musicInfo.work && this.musicInfo.work.type != 0;
      },
      showFontColor() {
        return '#f00';
      },
    },
    mounted() {
      const { tip_switch, area, is_special_user } = this.ActivityInfo;

      this.tip_switch = +tip_switch;
      this.isSpecial = is_special_user;

      if (area && area.length) {
        this.renderList = area
          .filter((item) => {
            return item.type == 1 || item.type == 2 || item.type == 8 || item.type == 9;
          })
          .sort((a, b) => {
            console.log(a, b);
            return Number(a.sort) - Number(b.sort);
          });
        area.forEach((item) => {
          if (item.picture) {
            for (let i = 0; i <= item.picture.length; i++) {
              if (item.picture[i] && +item.picture[i].resource_type && +item.picture[i].resource_type === 1) {
                this.$set(item.picture[i], 'isVideoPlay', false);
                this.$set(item.picture[i], 'isVideoPlayed', false);
              }
            }
          }
        });
        const buttonItem = area.find((item) => item.type == 3);
        this.buttonInfo = buttonItem ? buttonItem.button : null;
        this.musicInfo = area.find((item) => item.type == 5);
        const carouselItem = area.find((item) => item.type == 6);
        this.carouselInfo = carouselItem
          ? carouselItem.tips.map((item) => {
              return item.content;
            })
          : null;
        this.musicGuideInfo = area.find((item) => +item.type === 7);
      }
    },

    methods: {
      /**
       * @description @改变播放状态
       * @return @void
       */

      changePlayVideo(item) {
        item.isVideoPlayed = true;
        const video = document.getElementById(`video_${item.sort}`);

        if (video.paused) {
          this.playVideo(video, true, item);
        } else {
          this.pauseVideo(video, item);
        }
      },
      playVideo(video, flag = true, item) {
        video.play();
        item.isVideoPlay = flag;
      },
      pauseVideo(video, item) {
        video.pause();
        item.isVideoPlay = false;
      },
      handleClickImg(item) {
        this.post && this.post();
        // 点击图片跳转链接
        if (!item.pic_link) {
          return;
        }
        // this.trackClick('图片', item.pic_link);

        // const data = { url: `${item.pic_link}` };
        // this.$sdk.openPageWithJSON(JSON.stringify(data));
      },
    },
  };
</script>

<style lang="less" scoped>
  .wrapper {
    .content {
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
      padding: 0 0.3rem 0.2rem;
    }
    .banner {
      width: 100%;
      height: auto;
      margin-bottom: 0.24rem;
    }

    .detail-banner {
      width: 6.9rem;
      margin: 0 auto 0.24rem;
      border-radius: 0.12rem;
    }
  }
</style>
