<template>
  <div class="music_list_wrapper">
    <section v-if="HQlist && HQlist.length">
      <h2>优质投稿</h2>
      <MusicItem
        v-for="(item, index) in HQlist"
        :key="index"
        :activited="index === currentIndex"
        :playing="playing"
        :music="item"
        :uid="uid"
        :avatar="item.user_info.avatar_url"
        @click.native="handleClickMusic(item, index)"
        @action="handleClickAction($event, item, index)"
      ></MusicItem>
    </section>
    <section v-if="list && list.length">
      <van-list
        v-model="loading"
        :finished="finished"
        loadingText="正在加载..."
        finished-text="没有更多歌曲了哦～"
        :immediate-check="false"
        @load="onLoad"
      >
        <h2>最新投稿</h2>
        <MusicItem
          v-for="(item, index) in list"
          :key="index"
          :activited="index === currentIndex"
          :playing="playing"
          :music="item"
          :uid="uid"
          :avatar="item.user_info.avatar_url"
          @click.native="handleClickMusic(item, index)"
          @action="handleClickAction($event, item, index)"
        ></MusicItem>
      </van-list>
    </section>
  </div>
</template>

<script>
  import API from '@/api';
  import MusicItem from '../MusicList/MusicItem/index.vue';
  import { mapGetters } from 'vuex';
  import MusicStreamMixin from '../../mixins/MusicStreamMixin';

  export default {
    components: {
      MusicItem,
    },
    mixins: [MusicStreamMixin],
    props: {
      isFirstLoad: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('activity/activityTemplate', ['playListId']),
    },
    data() {
      return {
        loading: false,
        uid: 0,
        lastId: 0,
        finished: false,
        list: [],
        HQlist: [],
        playing: false,
        currentIndex: -1,
        pageTitle: '最新投稿',
      };
    },
    created() {
      this.getHQContributeList();
    },
    methods: {
      async getHQContributeList() {
        try {
          const data = await this.$http.TaskPost(API.GET_ACTIVITY_HQ_LIST, {
            playlist_id: this.playListId,
            last_id: 0,
          });
          const musicList =
            data.music_list && Object.prototype.toString.call(data.music_list) === '[object Array]'
              ? this.initList(data.music_list)
              : [];

          this.HQlist = [...musicList];
          this.initMusicList(musicList);
          console.log('initMusicList', musicList);

          this.getContributeList();
        } catch (e) {
          console.log(e);
        }
      },
      async getContributeList() {
        try {
          this.loading = true;
          const data = await this.$http.TaskPost(API.GET_ACTIVITY_LIST, {
            playlist_id: this.playListId,
            last_id: this.lastId,
          });

          this.finished = !!data.is_end;
          this.lastId = data.last_id;
          const musicList =
            data.music_list && Object.prototype.toString.call(data.music_list) === '[object Array]'
              ? this.initList(data.music_list)
              : [];

          console.log('appendMusicList', musicList);
          this.appendMusicList(musicList);

          this.list = [...this.list, ...musicList];
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      },
      /**
       * @description: 重置loading
       * @param {boolean} flag - loading状态
       * @return {void}
       */
      resetLoading(flag) {
        this.loading = flag;
      },

      /**
       * @description: 下拉刷新触发的方法
       * @return {void}
       */
      onLoad() {
        // 异步更新数据
        setTimeout(() => {
          this.getContributeList();
        }, 500);
      },

      handleClickMusic(item, index) {
        this.handleMusicPlay(item, index);
      },

      /**
       * @description: 点击底部bar
       * @param {string} action - 动作名
       * @param {object} item - 选中的歌曲
       * @param {object} index - 选中歌曲的索引
       * @return {void}
       */
      handleClickAction(action, item, index) {
        this.handleMusicClickAction(item, index, action);
      },
    },
  };
</script>

<style lang="less" scoped>
  .music_list_wrapper {
    position: relative;
    padding: 0rem 0.12rem 0.24rem;
    h2 {
      margin: 0.28rem 0rem;
      font-size: 0.32rem;
      width: 100%;
      text-align: center;
    }
  }
</style>
