<template>
  <div class="detail_list_wapper">
    <ul class="detail_list">
      <!-- 用户信息按钮 -->
      <li class="deatil_item user_info" @click.stop="handleClickAction('person')">
        <div class="avatar" :style="{ backgroundImage: `url(${avatar_img})` }">
          <div v-if="pendant" class="pendant" :style="{ backgroundImage: `url(${pendant})` }"></div>
        </div>
        <div class="content">
          <div class="username">{{ userInfo ? userInfo.nickname : '' }}</div>
          <ul class="decoration_list" v-if="userInfo && userInfo.medals && userInfo.medals.length">
            <li
              v-for="(medal, index) in userInfo.medals"
              :key="index"
              :style="{ backgroundImage: `url(${medal.icon})` }"
              class="decoration"
            ></li>
          </ul>
        </div>
        <div v-if="userInfo && userInfo.user_id !== uid" class="follow" @click.stop="handleClickAction('follow')">
          <img
            v-if="!(userInfo && userInfo.is_followed)"
            class="follow_icon"
            src="http://static1.kaixinyf.cn/img/lza5fa8e1505b590385524587.png"
          />
          <span v-if="!(userInfo && userInfo.is_followed)">关注</span>
          <span class="followed" v-else>已关注</span>
        </div>
      </li>
      <!-- 用户信息按钮结束 -->

      <!-- 图集 -->
      <li v-if="hasGallery" class="deatil_item small_item" @click.stop="handleClickAction('gallery')">
        <img class="small_item_icon" src="http://static1.kaixinyf.cn/img/lza5fa8e1a5100ac263975597.png" />
        <span class="deatil_item_text">图集</span>
      </li>
      <!-- 图集结束 -->

      <!-- 视频 -->
      <li v-if="!hasGallery" class="deatil_item small_item" @click.stop="handleClickAction('mv')">
        <img class="small_item_icon" src="http://static1.kaixinyf.cn/img/lza5fa8e189c8cca184861794.png" alt="" />
        <span class="deatil_item_text">视频</span>
      </li>
      <!-- 视频结束 -->

      <!-- 相似歌曲 -->
      <li class="deatil_item similar" @click.stop="handleClickAction('similar')">
        <p class="similar_title">相似歌曲</p>
        <p class="similar_desc">
          <span>查看更多</span>
          <img class="more_icon" src="http://static1.kaixinyf.cn/img/lza5fa8fa8924f58514463806.png" alt="" />
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      avatar_img: {
        type: String,
      },
      userName: {
        type: String,
        default: '',
      },
      pendant: {
        type: String,
        default: '',
      },
      userInfo: {
        type: Object,
        default: () => {},
      },
      uid: {
        type: Number | String,
        default: 0,
      },
      hasGallery: {
        type: Boolean | Object,
        default: false,
      },
    },
    computed: {
      // medals
      medalList() {
        this.userInfo && this.userInfo.medals;
      },
    },
    methods: {
      handleClickAction(action) {
        this.$emit('action', action);
      },
    },
  };
</script>

<style lang="less" scoped>
  .detail_list_wapper {
    width: 6.02rem;
    height: 0.88rem;
    overflow: scroll;
    overflow-y: hidden;
    display: flex;
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
    .detail_list {
      display: flex;
      white-space: nowrap;
      display: inline-flex;
      height: 0.88rem;
      padding-right: 0.24rem;
      .deatil_item {
        height: 0.88rem;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 0.12rem;
        display: flex;
        align-items: center;
        box-sizing: border-box;
      }
      & > li:not(:first-of-type) {
        margin-left: 0.12rem;
      }
      .user_info {
        padding: 0.12rem 0.24rem 0.12rem 0.12rem;
        box-sizing: border-box;
        display: flex;

        .avatar {
          position: relative;
          width: 0.64rem;
          height: 0.64rem;
          border-radius: 0.34rem;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 0.12rem;
          .pendant {
            position: absolute;
            left: -0.08rem;
            top: -0.08rem;
            width: 0.8rem;
            height: 0.8rem;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        .content {
          flex: 1;
          padding: 0;
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #cccccc;
          line-height: 0.24rem;
          margin-left: 0.04rem;
          margin-right: 0.24rem;
          display: flex;
          flex-direction: column;
          .decoration_list {
            display: flex;
            height: 0.28rem;
            margin-top: 0.08rem;
            & li:not(:first-child) {
              margin-left: 0.08rem;
            }
            .decoration {
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              width: 0.28rem;
              height: 0.28rem;
            }
          }
        }

        .follow {
          // width: 0.42rem;
          height: 0.36rem;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 0.18rem;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding: 0.08rem 0.12rem 0.08rem 0.08rem;
          .follow_icon {
            width: 0.2rem;
            height: 0.2rem;
            object-fit: contain;
          }
          span {
            margin-left: 0.04rem;
            // width: 0.2rem;
            height: 0.2rem;
            font-size: 0.2rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #eeeeee;
            line-height: 0.2rem;
          }
          .followed {
            color: #cccccc;
          }
        }
      }
      .small_item {
        width: 0.88rem;
        height: 0.88rem;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 0.12rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.12rem 0.2rem;
        box-sizing: border-box;
        .small_item_icon {
          width: 0.36rem;
          height: 0.36rem;
        }
        .deatil_item_text {
          height: 0.2rem;
          font-size: 0.24rem;
          font-weight: 400;
          color: #cccccc;
          line-height: 0.2rem;
          margin-top: 0.08rem;
        }
      }
      .similar {
        width: 1.56rem;
        height: 0.488rem;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 0.12rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.14rem 0.12rem;
        .similar_title {
          height: 0.24rem;
          font-size: 0.24rem;
          color: #cccccc;
          line-height: 0.24rem;
        }
        .similar_desc {
          color: rgba(255, 255, 255, 0.15);
          height: 0.24rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          display: flex;
          align-items: center;
          margin-top: 0.12rem;
          .more_icon {
            width: 0.24rem;
            height: 0.24rem;
            margin-left: 0.12rem;
          }
        }
      }
    }
  }
</style>
