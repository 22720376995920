<template>
  <MusicList
    ref="musicList"
    class="music_list"
    :list="list"
    :finished="finished"
    :playing="playing"
    @loadMore="handleLoadMore"
    @clickMusic="handleMusicAction"
    @action="handleClickAction"
    :currentIndex="currentIndex"
  ></MusicList>
</template>

<script>
  import MusicList from '../MusicList/index.vue';
  import API from '@/api';
  import MusicStreamMixin from '../../mixins/MusicStreamMixin';

  export default {
    components: {
      MusicList,
    },
    mixins: [MusicStreamMixin],
    props: {
      activity_id: {
        type: Number,
        required: true,
      },
      topic_id: {
        type: Number | String,
        required: true,
      },
      type: {
        type: Number,
        default: 1,
      },
      preview: {
        type: Number | String,
        default: 0,
      },
      music_show: {
        type: Number | String,
        default: 0,
      },
      // 音乐列表所在的活动名 -- 埋点使用
      name: {
        type: String,
        default: 'title',
      },
      //音乐列表类型，1人工列表，2话题列表
      musicListType: {
        type: Number,
        default: 2,
      },
      area_id: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        data: {},
        list: [],
        page: 1,
        finished: false,
        isReload: true,
        playing: false,
        currentIndex: -1,
        pageTitle: '活动页',
        lastId: 0,
        pvId: '',
      };
    },
    computed: {
      currentMusic() {
        return this.list[this.currentIndex];
      },
    },
    watch: {
      type(val) {
        this.isReload = true;
        this.lastId = 0;
        this.list = [];
        this.init('typeChange');
      },
    },
    created() {
      // sdk.pauseMusic();
      this.init();
    },
    methods: {
      /**
       * @description: 重置currentIndex
       * @return {void}
       */
      resetCurrentIndex(currentMusic) {
        const index = this.list.findIndex((item) => {
          return item.code === currentMusic.code;
        });
        this.currentIndex = index;
      },

      /**
       * @description: 初始化方法
       * @param {string} type - 请求类型 如果为typeChange则是切换tab时发出的请求
       * @return {void}
       */
      async init(type) {
        let postData;
        let res;

        if (+this.musicListType === 1) {
          // 人工列表
          postData = {
            area_id: this.area_id,
            lastId: this.lastId,
            limit: 10,
            type: 1,
          };
        } else if (+this.musicListType === 2) {
          // 话题列表
          postData = {
            topicId: this.topic_id,
            lastId: this.lastId,
            limit: 10,
            type: 2,
          };
        }
        res = await this.$http.TaskPost(API.GET_ACTIVITY_MUSIC_LIST, postData);
        this.lastId = res.lastId;

        // 记录当前播放的音乐
        const currentMusic = this.list[this.currentIndex];
        if (type) {
          this.finished = false;
          this.list = [];
        }
        if (this.isReload) {
          // 首次加载时，初始化客户端音乐列表
          res.music_list.map((item) => {
            this.$set(item, 'music_status', 0);
            this.$set(item, 'hasDownLoad', false);
            this.$set(item, 'liked', Boolean(item.is_liked));
          });
          this.data = res;
          this.pvId = res.pv_id;
          this.list = res.music_list;
          this.isReload = false;
          //   this.initMusicList(res.data.music_list);
          // 切换tab时重置curretnIndex
          currentMusic && this.resetCurrentIndex(currentMusic);
        } else {
          // 再次加载时，处理新数据，然后加到客户端音乐列表中
          res.music_list.map((item) => {
            this.$set(item, 'music_status', 0);
            this.$set(item, 'hasDownLoad', false);
            this.$set(item, 'liked', Boolean(item.is_liked));
          });
          this.list = [...this.list, ...res.music_list];
          this.$nextTick(() => {
            this.$refs.musicList.resetLoading();
          });
        }
        // 重置下拉刷新的 loading 的状态
        this.loading = false;
        if (res.is_end == 1) {
          this.finished = true;
        }
      },

      /**
       * @description: 初始化音乐列表并自动给播放
       * @param {array} list - 音乐列表数据
       * @return {void}
       */
      /* initMusicList(list) {
				let JSONStr = JSON.stringify(list);
				sdk.initMusicPlayerList(JSONStr, this.pageTitle);
			},*/

      /**
       * @description: 往播放器列表中插入音乐数据
       * @param {array} list - 音乐列表数据
       * @return {void}
       */
      /* appendMusicList(list) {
				let JSONStr = JSON.stringify(list);
				sdk.appendMusicPlayerList(JSONStr, this.pageTitle);
			},*/

      /**
       * @description: 客户端播控操作音乐的回调
       * @param {object} item - 音乐数据
       * @param {string} index - 音乐索引
       * @param {string} pt - 页面标题
       * @return void
       */
      /* handleMusicActionFromClient(item, index, pt) {
				if (pt === this.pageTitle) {
					this.handleMusicAction(item, index, true);
				} else {
					console.log('不是同一页面', pt);
				}
			}, */

      /**
       * @description: 初始化音乐列表状态
       * @return {void}
       */
      /* initMusicStatus() {
				this.list = this.list.map((item) => {
					return {
						...item,
						music_status: 0,
					};
				});
			},*/

      /**
       * @description: 加载更多
       * @return {void}
       */
      handleLoadMore() {
        if (this.finished) return;
        this.init();
      },

      /**
       * @description: 控制音乐播放
       * @param {object} item - 音乐信息
       * @param {number} index - 音乐索引
       * @param {boolean} client - 是否是客户端通知
       * @return {void}
       */
      handleMusicAction(item, index, client = false) {
        console.log('MusicAction', item, index);
        if (this.preview) {
          return;
        }
        item.music_status = 1;
        this.handleMusicPlay(item, index, client);
      },

      /**
       * @description: 触发歌曲事件
       * @param {object} item - 选中的歌曲
       * @param {object} index - 选中歌曲的索引
       * @param {string} action - 动作名
       * @return {void}
       */
      handleClickAction(item, index, action) {
        console.log(item, index, action);
        if (this.preview) {
          return;
        }
        this.handleMusicClickAction(item, index, action);
      },

      /**
       * @description: 请求接口上报更新热度
       * @param {number} music_id
       * @param {number} type
       * @return {void}
       */
      /* async updateHeat(music_id, type) {
				const postData = {
					activity_id: this.activity_id,
					music_id,
					type,
				};
				const url = '/activity/update_heat';
				const res = await request.post(url, postData);
			},*/

      /**
       * @description: 埋点方法
       * @param {string} element_name - 元素名
       * @param {string} music_user_id - 作者用户ID
       * @param {string} music_id - 音乐ID
       * @param {string} music_code - 音乐code
       * @param {string} pv_id - 向服务端请求的资源id
       * @param {string} remarks - 备注
       * @return:
       */
      /* trackClick(element_name, music_user_id, music_id, music_code, pv_id, remarks) {
				trackClick({ page_title: this.pageTitle, element_name, music_user_id, music_id, music_code, pv_id, remarks });
			},*/
    },
  };
</script>
