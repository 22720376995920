<template>
  <div class="progress">
    <div class="progress-block" v-for="(item, index) in prize" :key="item.id">
      <div
        :class="{
          'progress-line': true,
          firstBorder: index == 0,
          'first-line': index == 0 && prize.length > 4,
          'two-prize': prize.length == 2,
          'three-prize': prize.length == 3,
          'four-prize': prize.length == 4,
        }"
        :style="{ background: index < currentIndex ? '#FA6C00' : '#e8e8e8' }"
      ></div>
      <div class="progress-ponit">
        <Point
          :name="prizeTypeMap[+item.prize_type].name"
          :img="prizeTypeMap[+item.prize_type].img"
          :index="index"
          :currentIndex="currentIndex"
        ></Point>
      </div>
    </div>
  </div>
</template>
<script>
  import Point from './point.vue';
  export default {
    components: { Point },
    props: {
      prize: {
        type: Array,
        default: () => [],
      },
      currentIndex: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        pendantIcon: require('@/assets/images/activity/activity-template/pendant.png'),
        coinIcon: require('@/assets/images/activity/activity-template/coin.png'),
        medalIcon: require('@/assets/images/activity/activity-template/medal.png'),
        prizeTypeMap: {
          1: {
            img: require('@/assets/images/activity/activity-template/coin.png'),
            name: '金币',
          },
          2: {
            img: require('@/assets/images/activity/activity-template/pendant.png'),
            name: '挂件',
          },
          3: {
            img: require('@/assets/images/activity/activity-template/medal.png'),
            name: '勋章',
          },
        },
        innerProgreeWidth: 0,
      };
    },
    methods: {},
  };
</script>
<style lang="less" scoped>
  .progress {
    height: 1.26rem;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    /* IE 10+ */
    .progress-block {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .progress-line {
        width: 1.74rem;
        // border-radius: 0.05rem;
        height: 0.12rem;
        background: #e8e8e8;
      }
      .progress-ponit {
        position: absolute;
        right: 0;
      }
    }
  }

  .firstBorder {
    border-radius: 0.1rem 0 0 0.1rem;
  }

  .first-line {
    width: 0.56rem !important;
  }
  .two-prize {
    width: 3rem !important;
  }
  .three-prize {
    width: 2rem !important;
  }
  .four-prize {
    width: 1.5rem !important;
  }
</style>
