// import request from '@/lib/request';
// import sdk from '@/lib/sdk';
// import { trackClick } from '@/lib/track';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('activity/activityTemplate', ['activityId']),
  },
  created() {
    // /**
    //  * @description: 与客户端交互的行为定义
    //  * @param {string} play - 播放音乐
    //  * @param {string} pause - 暂停音乐
    //  * @param {string} download - 下载音乐
    //  * @param {string} like - 喜欢
    //  * @param {string} unlike - 取消喜欢
    //  * @param {string} mv - 跳转 MV 详情页
    //  * @param {string} share - 分享
    //  * @param {string} dislike - 不感兴趣
    //  * @param {string} next - 下一首
    //  * @param {string} follow - 关注
    //  * @param {string} unfollow - 取消关注
    //  * return void
    //  */
    // this.$sdkNew.register('ON_MUSIC_ACTION', (res, pt) => {
    //   console.log({ res, pt }, 'onMusicActionEvent');
    //   res = JSON.parse(res);
    //   let action = res.action;
    //   // sdk.setTitle(this.pageTitle);
    //   let targetItem = this.list[res.index];
    //   console.log(targetItem, action);
    //   let count;
    //   let pv_id = this.pvId || '';
    //   switch (action) {
    //     case 'download':
    //       targetItem.hasDownLoad = true;
    //       this.trackClick('下载', targetItem.user_info.user_id, targetItem.id, targetItem.code, pv_id, 1);
    //       break;
    //     case 'like':
    //       count = targetItem.counts.like_count;
    //       Number(count) == count && (count = +count + 1);
    //       targetItem.counts.like_count = count;
    //       targetItem.is_liked = 1;
    //       this.trackClick('喜欢', targetItem.user_info.user_id, targetItem.ab_test, targetItem.code, pv_id, '喜欢');
    //       break;
    //     case 'unlike':
    //       count = targetItem.counts.like_count;
    //       Number(count) == count && (count = +count - 1);
    //       targetItem.counts.like_count = count;
    //       targetItem.is_liked = 0;
    //       this.trackClick('喜欢', targetItem.user_info.user_id, targetItem.ab_test, targetItem.code, pv_id, '不喜欢');
    //       break;
    //     case 'next':
    //       // 播放下一首
    //       console.log('next');
    //       this.handleMusicActionFromClient(targetItem, res.index, pt);
    //       break;
    //     case 'dislike':
    //       let idx = res.index;
    //       idx++;
    //       if (idx >= this.list.length) {
    //         idx = 0;
    //       }
    //       targetItem = this.list[idx];
    //       this.handleMusicActionFromClient(targetItem, Number(idx), pt);
    //       break;
    //     case 'play':
    //       this.handleMusicActionFromClient(targetItem, res.index, pt);
    //       break;
    //     case 'pause':
    //       this.handleMusicActionFromClient(targetItem, res.index, pt);
    //       break;
    //     case 'follow':
    //       targetItem.user_info.is_followed = true;
    //       this.$toast({ message: `关注成功` });
    //       break;
    //     case 'unfollow':
    //       targetItem.user_info.is_followed = false;
    //       break;
    //     default:
    //       break;
    //   }
    // });
  },
  methods: {
    /**
     * @description: 控制音乐播放
     * @param {object} item - 音乐信息
     * @param {number} index - 音乐索引
     * @param {boolean} client - 是否是客户端通知
     * @return {void}
     */
    handleMusicPlay(item, index, client = false) {
      // 如果切歌，则重置所有歌曲状态
      if (index !== this.currentIndex) {
        console.log('切歌');
        this.initMusicStatus();
        item.music_status = 0;
        this.playing = true;
      }
      this.currentIndex = index;
      // 当前音乐状态为未开始播放或暂停状态是，播放音乐
      if (item.music_status === 0) {
        console.log('play', item, index);
        if (!client) {
        //   sdk.handleMusicByAction(Number(index), 'play', this.pageTitle);
        }
        item.music_status = 1;
        this.playing = true;
        item.counts.heat_count = Number(item.counts.heat_count) + 1;
        // this.updateHeat(item.id, 1);
      } else if (item.music_status === 2) {
        console.log('继续');
        if (!client) {
        //   sdk.handleMusicByAction(Number(index), 'play', this.pageTitle);
        }
        this.playing = true;
        item.music_status = 1;
      } else {
        console.log('暂停');
        if (!client) {
        //   sdk.handleMusicByAction(Number(index), 'pause', this.pageTitle);
        }
        this.playing = false;
        item.music_status = 2;
      }
      this.list[index] = item;
    //   trackClick({ page_title: this.pageTitle, element_name: '点击音频', pv_id: this.pvId || '', remarks: this.name });
    },

    /**
     * @description: 触发歌曲事件
     * @param {object} item - 选中的歌曲
     * @param {object} index - 选中歌曲的索引
     * @param {string} action - 动作名
     * @return {void}
     */
    handleMusicClickAction(item, index, action) {
      console.log(item.id);
      const pv_id = this.pvId || '';

      switch (action) {
        case 'like':
        //   sdk.handleMusicByAction(Number(index), action, this.pageTitle);
          this.updateHeat(item.id, 1);
          break;
        case 'unlike':
        //   sdk.handleMusicByAction(Number(index), action, this.pageTitle);
          this.updateHeat(item.id, 1);
          break;
        case 'mv':
          this.playing = false;
          this.currentIndex = -1;
        //   sdk.handleMusicByAction(Number(index), action, this.pageTitle);
        //   this.trackClick('MV', item.user_info.user_id, item.ab_test, item.code, pv_id);
          break;
        case 'share':
        //   sdk.handleMusicByAction(Number(index), action, this.pageTitle);
        //   this.trackClick('分享', item.user_info.user_id, item.ab_test, item.code, pv_id);
          break;
        case 'follow':
          // 关注
        //   sdk.handleMusicByAction(Number(index), action, this.pageTitle);
          break;
        case 'sing':
          // 跟唱
        //   sdk.handleMusicByAction(Number(index), 'acapella', this.pageTitle);
          break;
        case 'download':
          // 下载
        //   sdk.handleMusicByAction(Number(index), action, this.pageTitle);
          break;
        case 'person':
          // 跳转个人页面
          this.playing = false;
          this.currentIndex = -1;
        //   sdk.openPageWithJSON(
        //     JSON.stringify({
        //       url: `kuaiyin://profile?uid=${item.user_info.user_id}`,
        //     })
        //   );
          break;
        case 'similar':
          this.playing = false;
          this.currentIndex = -1;
          // 相似歌曲
        //   sdk.openPageWithJSON(
        //     JSON.stringify({
        //       url: `kuaiyin://musicRelate?code=${item.code}`,
        //     })
        //   );
          break;
        case 'gallery':
          this.playing = false;
          this.currentIndex = -1;
          // 跳转图集详情
        //   sdk.handleMusicByAction(Number(index), 'mv', this.pageTitle);
          break;
        default:
          return;
      }
    },

    /**
     * @description: 请求接口上报更新热度
     * @param {number} music_id
     * @param {number} type
     * @return {void}
     */
    async updateHeat(music_id, type) {
      const postData = {
        activity_id: this.activityId || this.$route.query.activity_id,
        music_id,
        type,
      };
      const url = '/activity/update_heat';
    //   const res = await request.post(url, postData);
    },

    /**
     * @description: 初始化音乐列表状态
     * @return {void}
     */
    initMusicStatus() {
      this.list = this.list.map((item) => {
        return {
          ...item,
          music_status: 0,
        };
      });
    },

    /**
     * @description: 初始化音乐列表并自动给播放
     * @param {array} list - 音乐列表数据
     * @return {void}
     */
    initMusicList(list) {
      let JSONStr = JSON.stringify(list);
    //   sdk.initMusicPlayerList(JSONStr, this.pageTitle);
    },

    /**
     * @description: 往播放器列表中插入音乐数据
     * @param {array} list - 音乐列表数据
     * @return {void}
     */
    appendMusicList(list) {
      let JSONStr = JSON.stringify(list);
    //   sdk.appendMusicPlayerList(JSONStr, this.pageTitle);
    },

    /**
     * @description: 客户端播控操作音乐的回调
     * @param {object} item - 音乐数据
     * @param {string} index - 音乐索引
     * @param {string} pt - 页面标题
     * @return void
     */
    handleMusicActionFromClient(item, index, pt) {
      console.log('item', item);
      console.log('index', index);
      console.log('pt', pt);
      if (pt === this.pageTitle) {
        this.handleMusicPlay(item, index, true);
      } else {
        console.log('不是同一页面', pt);
      }
    },

    /**
     * @description 初始化音乐列表数据
     * */

    initList(list) {
      return list.map((item) => {
        return {
          ...item,
          ...{
            music_status: 0,
            hasDownLoad: false,
            liked: Boolean(item.is_liked),
          },
        };
      });
    },

    // /**
    //  * @description: 埋点方法
    //  * @param {string} element_name - 元素名
    //  * @param {string} music_user_id - 作者用户ID
    //  * @param {string} music_id - 音乐ID
    //  * @param {string} music_code - 音乐code
    //  * @param {string} pv_id - 向服务端请求的资源id
    //  * @param {string} remarks - 备注
    //  * @return:
    //  */
    // trackClick(element_name, music_user_id, music_id, music_code, pv_id, remarks) {
    //   trackClick({ page_title: this.pageTitle, element_name, music_user_id, music_id, music_code, pv_id, remarks });
    // },
  },
};
