var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress"},_vm._l((_vm.prize),function(item,index){return _c('div',{key:item.id,staticClass:"progress-block"},[_c('div',{class:{
        'progress-line': true,
        firstBorder: index == 0,
        'first-line': index == 0 && _vm.prize.length > 4,
        'two-prize': _vm.prize.length == 2,
        'three-prize': _vm.prize.length == 3,
        'four-prize': _vm.prize.length == 4,
      },style:({ background: index < _vm.currentIndex ? '#FA6C00' : '#e8e8e8' })}),_c('div',{staticClass:"progress-ponit"},[_c('Point',{attrs:{"name":_vm.prizeTypeMap[+item.prize_type].name,"img":_vm.prizeTypeMap[+item.prize_type].img,"index":index,"currentIndex":_vm.currentIndex}})],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }