<template>
  <div class="task-one">
    <div class="task-one_info">
      <div class="one-left">
        <div class="left-title">
          <div>{{ taskInfo.task_title }}</div>
          <img
            v-if="currentPrize"
            :src="prizeTypeMap[currentPrize.prize_type] && prizeTypeMap[currentPrize.prize_type].img"
          />
        </div>
        <div class="left-intro">{{ taskInfo.sub_task_title }}</div>
      </div>
      <div class="one-right_btn" :style="{ background: btnColor[buttonType.type] }" @click="clickBtn()">
        {{ buttonType.name }}
      </div>
    </div>

    <Progress v-if="taskInfo.prize.length > 1" :prize="taskInfo.prize" :currentIndex="currentFinshIndex"></Progress>
  </div>
</template>
<script>
  import Progress from './progress.vue';
  import { mapMutations } from 'vuex';
  //   import { trackClick } from '@/lib/track';
  import Download from '@/utils/download';

  export default {
    components: { Progress },
    props: {
      taskInfo: {
        type: Object,
        default: () => {},
      },
      handleShareActivity: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        pendantIcon: require('@/assets/images/activity/activity-template/pendant.png'),
        coinIcon: require('@/assets/images/activity/activity-template/coin.png'),
        medalIcon: require('@/assets/images/activity/activity-template/medal.png'),
        prize: this.taskInfo.prize,
        currentPrize: null,
        // 已完成到哪一步的index(该领取哪一步奖励)
        currentFinshIndex: 0,
        // 当前停留到哪一步,有可能还未完成(做任务做到哪一步)
        currentIndex: 0,
        buttonType: {
          name: this.taskInfo.mini_text,
          type: 1, // 1:红底(去完成任务状态) 2:黄底(领取奖励) 3: 灰色底(一天只能做一次任务)
        },
        btnColor: {
          1: 'linear-gradient(90deg, #F87932 0%, #FF2B3D 100%)',
          2: '#FCC119',
          3: '#CCCCCC',
        },
        prizeTypeMap: {
          1: {
            img: require('@/assets/images/activity/activity-template/coin.png'),
            name: '金币',
          },
          2: {
            img: require('@/assets/images/activity/activity-template/pendant.png'),
            name: '挂件',
          },
          3: {
            img: require('@/assets/images/activity/activity-template/medal.png'),
            name: '勋章',
          },
          5: {
            img: require('@/assets/images/activity/activity-template/lottery.png'),
            name: '抽奖',
          },
          6: {
            img: 'http://static1.kaixinyf.cn/img/lza63f46641b6bf8432495400.png',
            name: '会员',
          },
          7: {
            img: 'http://static1.kaixinyf.cn/img/lza63f4665e89485574562060.png',
            name: '提现券',
          },
        },
      };
    },
    mounted() {
      this.getNowPrize();
    },
    watch: {
      taskInfo: {
        handler() {
          // console.log(this.taskInfo, 'watch prize change--');
          this.getNowPrize();
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      ...mapMutations('activityPage/activityTemplate', ['setShareInfo']),
      // 获取当前做任务的prize
      getNowPrize() {
        this.prize = this.taskInfo.prize;
        const date = new Date();
        const getToday = date.getFullYear() + '' + (date.getMonth() + 1) + '' + date.getDate();
        this.prize.some((item, index) => {
          this.currentIndex = index;
          // this.currentFinshIndex = index + 1;
          this.currentPrize = this.prize[index];
          // 如果是初始化
          if (item.task_status && item.task_status == 'init') {
            this.buttonType.name = this.taskInfo.mini_text;
            this.buttonType.type = 1;
            return true;
          }
          // 如果任务开始做
          if (item.task_status == 'complete') {
            // 还未做
            if (item.receive_status == '') {
              this.buttonType.name = this.taskInfo.mini_text;
              this.buttonType.type = 1;
              return true;
            }
            if (item.receive_status == 'wait') {
              this.currentFinshIndex = index + 1;
              this.buttonType.name = '领取奖励';
              this.buttonType.type = 2;
              return true;
            }
            if (item.receive_status == 'receive') {
              this.currentFinshIndex = index + 1;

              if (item.task_date == +getToday && index < this.prize.length) {
                this.buttonType.name = '明日再来';
                this.buttonType.type = 3;
                return true;
              }

              if (this.taskInfo.task_cycle == 2 && this.prize.length == 1) {
                this.buttonType.name = '明日再来';
                this.buttonType.type = 3;
                return true;
              }

              if (index + 1 == this.prize.length) {
                this.buttonType.name = '已完成';
                this.buttonType.type = 3;
                return true;
              }
            }
          }
        });
        // console.log(`this.currentPrize`, this.currentPrize);
      },
      async performTask() {
        // 类型为 跳转、分享的任务需要手动去完成任务
        const process_task = this.$api.ACTIVITY_PROCESS_TASK_V3_API;

        let postData = {
          id: this.$route.query.activity_id,
          task_id: this.taskInfo.id,
        };

        const res = await this.$post(process_task, postData);

        if (+res.code == 0 && res.data.result == true && this.prize && this.prize[this.currentIndex]) {
          // 手动修改prize状态
          this.prize[this.currentIndex].receive_status = 'wait';
          this.prize[this.currentIndex].task_status = 'complete';

          this.getNowPrize();
        }

        return res;
      },
      async clickBtn() {
        console.log(`handlePost`);
        try {
          let jump = () => {
            let url;

            if ((this.$platform.Android || this.$platform.iOS) && this.$platform.WeiXin) {
              // url = Download.downLoad('yyb');
              this.showIntro = true;
              document.body.style.overflow = 'hidden';
              return;
            } else if ((this.$platform.Android || this.$platform.iOS) && !this.$platform.WeiXin) {
              url = Download.downLoad('yyb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else if (this.$platform.iOS && !this.$platform.WeiXin) {
              url = Download.downLoad('share_ios');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else {
              url = Download.downLoad('yyb');
            }
            window.location.href = url;
          };
          jump();
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },

      async switchTaskType() {
        // "receive_status": "wait", // no-不需要领取,wait-待领取,receive-已领取
        //  "task_status": "complete", // init-任务初始化,complete-任务完成
        // 执行任务
        const process_task = this.$api.ACTIVITY_PROCESS_TASK_V3_API;
        // 关注任务
        const follow_user = this.$api.ACTIVITY_FOLLOW_USER_V3_API;
        // 领取奖励
        const receive_reward = this.$api.ACTIVITY_RECEIVE_REWARD_V3_API;

        switch (+this.taskInfo.task_type) {
          case 1:
            // 跳转

            {
              await this.performTask();

              const SCHEME = 'kuaiyin://web?web_url=';

              let data = {
                url: SCHEME + encodeURI(this.taskInfo.task_ext_param),
              };

              window.location.href = this.taskInfo.task_ext_param;
              // this.$sdkNew.appCall('OPEN_PAGE_WITH_JSON', JSON.stringify(data));
            }
            break;
          case 2:
          case 6:
            //  发布
            {
              // await this.performTask();
              const data = {
                title: window.origin.title,
                topic_id: process.env.NODE_ENV === 'production' ? '16778' : '19616',
              };
              // this.$sdkNew.appCall('UPLOAD_OPUS', JSON.stringify(data));
            }
            break;
          case 3:
            {
              // 分享活动
              // await this.performTask();
              this.setShareInfo({ hasShare: true, taskId: this.taskInfo.id });
              this.handleShareActivity();
            }
            break;
          case 4:
            // 关注用户
            {
              let postData = {
                id: this.$route.query.activity_id,
                task_id: this.taskInfo.id,
                follow_uid: this.taskInfo.task_ext_param,
              };

              const res = await this.$post(follow_user, postData);

              if (+res.code == 0 && this.prize && this.prize[this.currentIndex]) {
                // 手动修改prize状态
                this.prize[this.currentIndex].receive_status = 'wait';
                this.prize[this.currentIndex].task_status = 'complete';
                if (res.data.result && res.data.result.user_info)
                  this.$toast.success(`成功关注用户"${res.data.result.user_info.nickname}"`);

                this.getNowPrize();
              }
            }
            break;
          case 5:
            {
              // 评论
              // await this.performTask();

              let data = {
                url: `kuaiyin://videoDetail?id=${this.taskInfo.task_ext_param}&action=comment`,
              };

              console.log(data, 'data---');

              // this.$sdkNew.appCall('OPEN_PAGE_WITH_JSON', JSON.stringify(data));
            }
            break;
          default:
            break;
        }
      },

      /**
       * @description 打开弹窗
       */

      openModal(type, title, desc, img) {
        this.$store.dispatch('openModal', {
          modalType: 'activity_task_modal',
          pos: {
            value: 'bottom',
          },
          props: {
            modalType: +type,
            title,
            desc,
            img,
          },
          btnFn: () => {
            this.$store.dispatch('closeModal');
          },
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .task-one {
    .task-one_info {
      margin: 0.3rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .one-left {
        .left-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          & > div {
            max-width: 3.6rem;
            height: 0.3rem;
            font-size: 0.3rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 0.3rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          & > img {
            margin-left: 0.1rem;
            width: 0.34rem;
            height: 0.34rem;
          }
        }
        .left-intro {
          margin-top: 0.16rem;
          width: 4.08rem;
          height: 0.24rem;
          font-size: 0.22rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 0.24rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .one-right_btn {
        width: 17.44rem;
        height: 0.56rem;
        border-radius: 0.28rem;
        font-size: 0.24rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 0.56rem;
        text-align: center;
        // background: linear-gradient(90deg, #f87932 0%, #ff2b3d 100%);
      }
    }
  }
</style>
