// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../assets/images/activity/activity-template/first-rank-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".first-rank-box[data-v-0c53768c]{box-sizing:border-box;position:relative;height:2.16rem;padding-top:1.22rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}.nickname[data-v-0c53768c]{margin:0 auto .14rem .36rem;font-size:.3rem;line-height:.3rem;color:#fff;font-weight:700}.tip[data-v-0c53768c]{margin-left:.18rem;font-size:.24rem;line-height:.24rem;color:#fff;font-weight:500}.avatar[data-v-0c53768c]{display:inline-block;width:1.26rem;height:1.26rem;position:absolute;top:.42rem;right:.54rem;border-radius:.75rem;border:1px solid #fff;background-color:#d8cfd2}", ""]);
// Exports
module.exports = exports;
