<template>
  <!-- && +music.is_valid === 1" -->
  <div
    v-if="!music.is_del && +music.is_valid === 1"
    ref="musicItemRef"
    class="music_item_wrapper"
    :class="{ activited: activited, hasGallery: showGalleryList }"
  >
    <div
      v-show="activited"
      class="blur_wapper"
      :style="{ backgroundImage: `url(${music.cover})` }"
      :class="{ activited: activited }"
    ></div>
    <div class="content_wrapper" :class="{ activited: activited }">
      <div class="content">
        <div class="header">
          <Avatar
            v-show="!showGalleryList && avatar"
            class="avatar"
            :activited="activited"
            :avatar_img="music.user_info.avatar_url"
            :playing="playing"
          ></Avatar>
          <div class="info">
            <!-- 标题 -->
            <div class="music_title">{{ music.title }}</div>
            <!-- 标题结束 -->

            <!-- tag区域 -->
            <div class="music_desc">
              <Tag class="tag" :icon="coutIcon" :activited="activited" activeColor="#1CCBBF">
                <van-count-down
                  v-if="activited"
                  ref="count"
                  format="mm:ss"
                  :time="+music.play_time * 1000"
                  :auto-start="playing"
                />
                <template v-else>{{ formatCountTime(music.play_time) }}</template>
              </Tag>
              <Tag v-if="music.tags.length !== 0" class="tag" :activited="activited" activeColor="#27ADE0">{{
                music.tags[0].name
              }}</Tag>
              <span class="subtitle">{{ music.description }}</span>
            </div>
            <!-- tag区域结束 -->
          </div>
        </div>

        <!-- 图集区域 -->
        <!-- @action="$emit('action', $event)" -->
        <GalleryList
          v-if="showGalleryList"
          class="gallery_list"
          :galleryList="music.atlas_info.atlas_pics"
          :galleryInfo="music.atlas_info"
          @action="handleClickImg"
        ></GalleryList>
        <!-- 图集区域结束 -->

        <transition name="scale">
          <div v-if="activited">
            <!-- 滑动区域 -->
            <DetailList
              class="detail_list"
              :pendant="music.user_info.avatar_pendant"
              :avatar_img="avatar"
              :userInfo="music.user_info"
              :uid="uid"
              :hasGallery="music.atlas_info"
              @action="$emit('action', $event)"
            ></DetailList>
            <!-- 滑动区域结束 -->

            <!-- 功能区域 -->
            <MethodList
              class="method_list"
              :liked="+music.is_liked === 1"
              :hasDownLoad="music.hasDownLoad"
              :counts="music.counts"
              @action="$emit('action', $event)"
            ></MethodList>
            <!-- 功能区域结束 -->
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import Avatar from './Avatar';
  import Tag from './Tag';
  import GalleryList from './GalleryList';
  import DetailList from './DetailList';
  import MethodList from './MethodList';

  export default {
    components: {
      Avatar,
      Tag,
      GalleryList,
      DetailList,
      MethodList,
    },
    props: {
      activited: {
        type: Boolean,
        default: false,
      },
      playing: {
        type: Boolean,
        default: false,
      },
      music: {
        type: Object,
        default: () => {},
      },
      uid: {
        type: Number | String,
        default: 0,
      },
      avatar: {
        type: String,
        default: '',
      },
    },
    watch: {
      // 根据播放状态设置定时器
      playing(val) {
        if (this.activited) {
          this.$nextTick(() => {
            val ? this.$refs.count.start() : this.$refs.count.reset();
          });
        }
      },
    },
    computed: {
      // 是否展示图集列表 -- 图片数量大于3展示
      showGalleryList() {
        return this.music.atlas_info && this.music.atlas_info.atlas_pics && this.music.atlas_info.atlas_pics.length > 3;
      },
      // 计时器icon
      coutIcon() {
        const url = this.activited
          ? 'https://static1.kaixinyf.cn/img/lza5fa8e12c16b25772611011.png'
          : 'https://static1.kaixinyf.cn/img/lza5fa92e439d526110911698.png';
        return url;
      },
    },
    methods: {
      /**
       * @description: 格式化时间
       * @param {number} s - 时间戳
       * @return {string} 00:00 格式的字符串
       */
      formatCountTime(s) {
        let h = Math.floor(s / 60);
        s = s % 60;
        h += '';
        s += '';
        h = h.length == 1 ? '0' + h : h;
        s = s.length == 1 ? '0' + s : s;
        return h + ':' + s;
      },

      /**
       * @description: 点击图集
       * @param {string} action
       * @return {void}
       */
      handleClickImg(action) {
        this.activited && this.$emit('action', action);
      },
    },
  };
</script>

<style lang="less" scoped>
  .music_item_wrapper {
    position: relative;
    border-radius: 0.24rem;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 0.24rem;
    max-height: 1.48rem;
    height: auto;
    transform: translateZ(0);
    transition: max-height 0.4s;
    backface-visibility: hidden;
    perspective: 1000;
    &.hasGallery {
      max-height: 2.84rem;
    }
    .blur_wapper {
      position: absolute;
      border-radius: 0.24rem;
      top: 0;
      left: -10%;
      width: 120%;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      filter: blur(10px);
    }
    .content_wrapper {
      position: relative;
      padding: 0;
      border-radius: 0.24rem;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      background: #ffffff;
      transition: background 0.4s;
      backface-visibility: hidden;
      .content {
        padding: 0.24rem;
        height: auto;
        .header {
          display: flex;
          .avatar {
            width: 1.16rem;
          }
          .info {
            flex: 1;
            .music_title {
              max-width: 5.54rem;
              font-size: 0.28rem;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: bold;
              color: #333333;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              -webkit-line-clamp: 3;
            }
            .music_desc {
              margin-top: 0.2rem;
              display: flex;
              align-items: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 5.5rem;
              .tag {
                margin-right: 0.12rem;
              }
              .subtitle {
                color: #bbbbbb;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .gallery_list {
          margin-top: 0.24rem;
        }
        .detail_list {
          margin-top: 0.24rem;
        }
        .method_list {
          margin-top: 0.24rem;
        }
      }
    }
    &.activited {
      max-height: 4rem;
      &.hasGallery {
        // max-height: 1.42rem;
        max-height: 5.52rem;
      }
      .content_wrapper {
        background: rgba(0, 0, 0, 0.6);
        .content {
          .info {
            // padding-top: 0.04rem;
            .music_title {
              color: #ffffff;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
            }
          }
        }
      }
    }
  }
  .van-count-down {
    color: #1ccbbf;
    font-size: 0.24rem;
    height: 0.28rem;
    line-height: 0.28rem;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  // .scale-enter-active,
  // .scale-leave-active {
  // 	transition: transform 0.4s;
  // }
  // .scale-enter, .scale-leave-to /* .fade-leave-active below version 2.1.8 */ {
  // 	transform: scale3d(1, 0, 1)
  // }
</style>
