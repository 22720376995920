<template>
  <div class="list_title_wrap">
    <h2 class="list_title_text" v-if="music_show == 2">{{ music_show_text }}</h2>
    <div class="tabs" v-else-if="music_show == 1">
      <div class="tab_item" @click="handleClickItem(0)" :class="{ actived: currentIndex === 0 }">
        <span>热门</span>
        <div v-if="currentIndex === 0" class="actived_flag"></div>
      </div>
      <div class="tab_item" @click="handleClickItem(1)" :class="{ actived: currentIndex === 1 }">
        <span>最新</span>
        <div v-if="currentIndex === 1" class="actived_flag"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      music_show: {
        type: Number | String,
        default: 0,
      },
      music_show_text: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        currentIndex: 0,
      };
    },
    methods: {
      handleClickItem(index) {
        this.currentIndex = index;
        this.$emit('tabChange', index);
      },
    },
  };
</script>

<style lang="less" scope>
  .list_title_wrap {
    width: 7.5rem;
    height: 0.96rem;
    background: #ffffff;
    border-bottom: 0.01rem solid #eeeeee;
    border-radius: 0.24rem 0.24rem 0px 0px;
    padding: 0.32rem 0;
    box-sizing: border-box;
    display: flex;
    .list_title_text {
      font-size: 0.32rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 0.32rem;
      margin: 0 auto;
      text-align: center;
    }
    .tabs {
      display: flex;
      align-items: center;
      width: 100%;

      .tab_item {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 0.32rem;
        &.actived {
          font-weight: bold;
          color: #333333;
        }
        .actived_flag {
          position: absolute;
          bottom: -0.2rem;
          width: 0.32rem;
          height: 0.08rem;
          background: #ff2b3d;
          border-radius: 0.04rem;
        }
      }
    }
  }
</style>
