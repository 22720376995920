<template>
  <div class="tab-box">
    <nav class="tab">
      <aside
        v-for="(tab, key, index) in tabMap"
        :key="index"
        class="tab-item"
        :class="{ active: activeTab === key }"
        @click="changeTab(key)"
      >
        <h3>
          {{ tab }}
        </h3>
        <hr />
      </aside>
    </nav>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    computed: {
      ...mapGetters('activity/activityTemplate/', ['activeTab', 'tabMap', 'activityName']),
    },
    created() {
      const tab = this.$route.query.tab || '';
      if (tab && ['rule', 'rank', 'last'].includes(tab)) {
        this.changeTab(tab);
      }
    },
    methods: {
      ...mapMutations('activity/activityTemplate/', ['setActiveTab']),
      changeTab(type) {
        if (type !== this.activeTab) {
          this.handleTabChange(type);
        }
      },
      handleTabChange(type) {
        this.setActiveTab(type);
      },
    },
  };
</script>

<style lang="less" scoped>
  .tab-box {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
    background-color: #ffffff;
  }
  .tab {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.24rem 0.38rem 0.12rem 0.38rem;
    box-sizing: border-box;
    .tab-item {
      h3 {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 0.12rem;
        font-size: 0.32rem;
        line-height: 0.32rem;
        color: #666;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
      hr {
        width: 0;
        height: 0;
        background-color: #ff2b3d;
        border: none;
        border-radius: 2px;
        margin: 0 auto;
      }
    }
    .active {
      h3 {
        color: #333;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
      }
      hr {
        width: 0.28rem;
        height: 4px;
      }
    }
  }
</style>
