<template>
  <ul class="playing_icon_wrapper">
    <li
      v-for="(item, index) in itemList"
      :key="index"
      class="playing_icon_item"
      :class="{ pause: !playing }"
      :style="{ height: item.height, animationDelay: index * 0.1 + 's' }"
    ></li>
  </ul>
</template>

<script>
  export default {
    props: {
      playing: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        itemList: [
          {
            height: '0.12rem',
          },
          {
            height: '0.25rem',
          },
          {
            height: '0.32rem',
          },
          {
            height: '0.18rem',
          },
          {
            height: '0.18rem',
          },
          {
            height: '0.1rem',
          },
          {
            height: '0.24rem',
          },
          {
            height: '0.32rem',
          },
          {
            height: '0.28rem',
          },
          {
            height: '0.16rem',
          },
          {
            height: '0.08rem',
          },
        ],
      };
    },
  };
</script>

<style lang="less" scoped>
  .playing_icon_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .playing_icon_item {
      width: 0.04rem;
      background: #ffffff;
      border-radius: 0.04rem;
      opacity: 1.5;
      margin-right: 0.044rem;
      animation: wave 0.8s infinite;
      // animation-play-state: paused;
      &.pause {
        animation-play-state: paused;
      }
    }
  }
  @keyframes wave {
    0% {
      transform: scaleY(1);
    }

    50% {
      transform: scaleY(1.8);
    }

    100% {
      transform: scaleY(1);
    }
  }
</style>
