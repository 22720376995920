<template>
  <div class="progress-point">
    <div class="point-title">第{{ index + 1 }}次</div>
    <div class="ponint-content" :style="{ background: currentIndex >= index + 1 ? '#fa6c00' : '#E8E8E8' }"></div>
    <div class="ponit-intro">
      <div>{{ name }}</div>
      <img :src="img" />
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      name: {
        type: String,
        default: '',
      },
      img: {
        type: String,
        default: '',
      },
      index: {
        type: Number,
        default: 0,
      },
      currentIndex: {
        type: Number,
        default: 0,
      },
    },
  };
</script>
<style lang="less" scoped>
  .progress-point {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    // min-width: 0.4rem;
    text-align: center;
    position: relative;

    // left: 10%;
    .point-title {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.24rem;
      position: absolute;
      top: -0.2rem;
      min-width: 0.8rem;
    }

    .ponint-content {
      width: 0.2rem;
      height: 0.2rem;
      border: 0.02rem solid #ffffff;
      border-radius: 50%;
      margin: 0.14rem 0;
    }

    .ponit-intro {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.24rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: -0.2rem;
      min-width: 0.8rem;

      & > img {
        width: 0.24rem;
        height: 0.24rem;
        margin-left: 0.06rem;
      }
    }
  }
</style>
