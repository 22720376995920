<template>
  <ul class="method_list">
    <li class="method_item" @click.stop="handleClickAction('download')">
      <img class="method_icon" :src="downloadIcon" alt="" />
      <span class="method_text">缓存</span>
    </li>

    <li class="method_item" @click.stop="handleClickAction(liked ? 'unlike' : 'like')">
      <img class="method_icon" :src="likeIcon" alt="" />
      <span class="method_text">{{ counts && counts.like_count ? counts.like_count : 0 }}</span>
    </li>

    <li class="method_item" @click.stop="handleClickAction('sing')">
      <img class="method_icon" src="http://static1.kaixinyf.cn/img/lza5fa90f9011759103825998.png" alt="" />
      <span class="method_text">跟唱</span>
    </li>

    <li class="method_item" @click.stop="handleClickAction('share')">
      <img class="method_icon" src="http://static1.kaixinyf.cn/img/lza5fa90fda24a01838476526.png" alt="" />
      <span class="method_text">更多</span>
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      hasDownload: {
        type: Boolean,
        default: false,
      },
      liked: {
        type: Boolean,
        default: false,
      },
      counts: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      downloadIcon() {
        return this.hasDownload
          ? 'http://static1.kaixinyf.cn/img/lza5fa9fd8c88b2e965988673.png'
          : 'http://static1.kaixinyf.cn/img/lza5fa9fd02565f0609297125.png';
      },
      likeIcon() {
        return this.liked
          ? 'http://static1.kaixinyf.cn/img/lza5faa491a10f99114812275.png'
          : 'http://static1.kaixinyf.cn/img/lza5faa4965b3733814839335.png';
      },
    },
    methods: {
      handleClickAction(action) {
        this.$emit('action', action);
      },
    },
  };
</script>

<style lang="less" scoped>
  .method_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: PingFangSC-Semibold, PingFang SC;
    padding: 0 0.13rem;
    // margin-top: -.04rem;
    .method_item {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 0.74rem;
      .method_icon {
        width: 0.36rem;
        height: 0.36rem;
      }
      .method_text {
        height: 0.2rem;
        font-size: 0.2rem;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.75);
        line-height: 0.2rem;
        margin-top: 0.08rem;
      }
    }
  }
</style>
