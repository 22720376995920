<template>
  <div class="tag_wrapper" :class="{ activited: activited }">
    <img class="icon" v-if="icon" :src="icon" />
    <span :style="{ color: activited ? activeColor : '#bbbbbb' }">
      <slot></slot>
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      activeColor: {
        type: String,
        default: '#bbbbbb',
      },
      activited: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style lang="less" scoped>
  .tag_wrapper {
    height: 0.32rem;
    background: #f9f9f9;
    border-radius: 0.04rem;
    padding: 0 0.08rem;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    span {
      font-size: 0.24rem;
      font-weight: 400;
      display: flex;
    }
    .icon {
      width: 0.24rem;
      height: 0.24rem;
      object-fit: contain;
      margin-right: 0.08rem;
    }
    &.activited {
      background: rgba(233, 255, 254, 0.14);
    }
  }
</style>
