<template>
  <div class="music_list_wrapper">
    <van-list
      v-model="loading"
      :finished="finished"
      loadingText="正在加载..."
      finished-text="没有更多歌曲了哦～"
      :immediate-check="false"
      @load="onLoad"
    >
      <MusicItem
        v-for="(item, index) in list"
        :key="index"
        :activited="index === currentIndex"
        :playing="playing"
        :music="item"
        :uid="uid"
        :avatar="item.user_info.avatar_url"
        @click.native="handleClickMusic(item, index)"
        @action="handleClickAction($event, item, index)"
      ></MusicItem>
    </van-list>
  </div>
</template>

<script>
  import MusicItem from './MusicItem/index';
  export default {
    components: {
      MusicItem,
    },
    props: {
      // 列表类型 0-我发布的 1-任务大厅 2-我参与的
      type: {
        type: Number,
        default: 0,
      },
      list: {
        type: Array,
        default: [],
      },
      finished: {
        type: Boolean,
        default: false,
      },
      isFirstLoad: {
        type: Boolean,
        default: false,
      },
      playing: {
        type: Boolean,
        default: false,
      },
      currentIndex: {
        type: Number,
        default: -1,
      },
    },
    data() {
      return {
        loading: false,
        uid: 0,
      };
    },
    created() {},
    methods: {
      handleClickMusic(item, index) {
        this.$emit('clickMusic', item, index);
      },

      /**
       * @description: 重置loading
       * @param {boolean} flag - loading状态
       * @return {void}
       */
      resetLoading(flag) {
        this.loading = flag;
      },

      /**
       * @description: 下拉刷新触发的方法
       * @return {void}
       */
      onLoad() {
        console.log('load');
        // 异步更新数据
        setTimeout(() => {
          this.$emit('loadMore');
        }, 500);
      },

      /**
       * @description: 点击底部bar
       * @param {string} action - 动作名
       * @param {object} item - 选中的歌曲
       * @param {object} index - 选中歌曲的索引
       * @return {void}
       */
      handleClickAction(action, item, index) {
        this.$emit('action', item, index, action);
      },
    },
  };
</script>

<style lang="less" scoped>
  .music_list_wrapper {
    position: relative;
    padding: 0.24rem 0.12rem;
    background: #ffffff;
  }
</style>
