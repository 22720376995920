<template>
  <div class="rules">
    <h2 class="rules_title">{{ title }}</h2>
    <p class="rules_content">
      {{ detail }}
    </p>
    <div v-if="is_jump == 1" class="rules_button">
      <span class="rules_button_text" @click="handleJump">{{ jump_title }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      detail: {
        type: String,
        default: '',
      },
      is_jump: {
        type: Number,
        default: false,
      },
      jump_title: {
        type: String,
        default: '',
      },
      jump_link: {
        type: String,
        default: '',
      },
    },
    methods: {
      // 跳转
      handleJump() {
        if (this.is_jump != 1) {
          return;
        }
        let data = {
          url: this.jump_link,
        };
        // this.$sdk.openPageWithJSON(JSON.stringify(data));
      },

      /**
       * @description: 埋点方法
       * @param {string} element_name - 元素名
       * @return {void}
       */
      trackClick(element_name, extra_remarks = '') {
        if (extra_remarks) {
          trackClick({ page_title: '活动页', element_name, remarks: `${this.remarks};${extra_remarks}` });
        } else {
          trackClick({ page_title: '活动页', element_name, remarks: this.remarks });
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .rules {
    background: #ffffff;
    border-radius: 0.24rem;
    padding-top: 0.24rem;
    padding-bottom: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    .rules_title {
      margin: 0 auto;
      background-image: url('https://static1.kaixinyf.cn/img/lza62ff61723e5b5803483667.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 0.32rem;
      font-weight: bold;
      color: #58556c;
      line-height: 0.32rem;
      padding: 0.3rem;
      text-align: center;
    }
    .rules_content {
      font-size: 0.24rem;
      font-weight: 300;
      color: #999999;
      line-height: 0.32rem;
      letter-spacing: 0.01rem;
      margin: 0.06rem 0.54rem 0.32rem 0.54rem;
    }
    .rules_button {
      margin: 0 0.54rem;
      height: 0.6rem;
      background: #f9f9f9;
      border-radius: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .rules_button_text {
        font-size: 0.28rem;
        font-weight: bold;
        color: #58556c;
        line-height: 0.28rem;
      }
    }
  }
</style>
