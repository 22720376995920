<template>
  <div class="modal">
    <div class="center">
      <img class="title" src="@/assets/images/activity/activity-template/modal-title.png" />
      <div class="center-tip" v-html="'快登陆快音APP领取奖励<br/>参与抽奖吧！更多大奖等你拿'"></div>
      <div class="bottom">
        <a :href="buttonLink">
          <div class="bottom-btn" @click="handlePost">
            领取奖励
            <img class="click_hand" src="@/assets/images/activity/activity-template/hand.png" />
          </div>
        </a>

        <div class="bottom-desc1">登陆快音app</div>
        <div class="bottom-desc2">仅在app内抽中奖品可领取并获得哦</div>
      </div>
    </div>
    <!-- <img class="icon" src="@/assets/images/activity/activity-template/modal-close-icon.png" /> -->
    <IntroModal v-if="showIntro" @close="closeIntro" />
  </div>
</template>

<script>
  import Download from '@/utils/download';
  import IntroModal from '@/components/Modal/IntroModal/index.vue';

  export default {
    components: { IntroModal },
    data() {
      return {
        buttonLink: 'javascript:void(0);',
        showIntro: false,
      };
    },
    mounted() {
      if ((this.$platform.Android || this.$platform.iOS) && !this.$platform.WeiXin && !this.$platform.QQ) {
        const SCHEME =
          (this.$route.query.app_name == 'undefined' ? 'kuaiyin' : this.$route.query.app_name) + '://web?web_url=';
        let web_url;

        if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
          web_url = `http://h5.rd.kaixinyf.cn/template/activity_template_v3?activity_id=${this.$route.query.activity_id}&position=${this.$route.query.position}`;
        } else if (process.env.NODE_ENV === 'pub') {
          web_url = `https://h5.pub.kaixinyf.cn/template/activity_template_v3?activity_id=${this.$route.query.activity_id}&position=${this.$route.query.position}`;
        } else if (process.env.NODE_ENV === 'production') {
          web_url = `https://h5.kaixinyf.cn/template/activity_template_v3?activity_id=${this.$route.query.activity_id}&position=${this.$route.query.position}`;
        }

        this.buttonLink = `${SCHEME}${this.$platform.Android ? encodeURIComponent(web_url) : web_url}`;
      } else {
        this.buttonLink = 'javascript:void(0);';
      }
    },
    methods: {
      handlePost() {
        this.$track({
          page_title: '活动分享落地页',
          element_name: '下载app',
        });
        console.log(`handlePost`);
        try {
          let jump = () => {
            let url;

            if ((this.$platform.Android || this.$platform.iOS) && (this.$platform.WeiXin || this.$platform.QQ)) {
              // url = Download.downLoad('mb');
              this.showIntro = true;
              document.body.style.overflow = 'hidden';
              return;
            } else if ((this.$platform.Android || this.$platform.iOS) && !this.$platform.WeiXin) {
              url = Download.downLoad('mb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else if (this.$platform.iOS && !this.$platform.WeiXin) {
              url = Download.downLoad('mb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else {
              url = Download.downLoad('mb');
            }
            window.location.href = url;
          };
          jump();
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
      closeIntro() {
        this.showIntro = false;
        document.body.style.overflow = 'auto';
      },
    },
  };
</script>

<style lang="less" scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow: hidden;
  }
  .title {
    width: 4.2rem;
    height: 0.78rem;
    margin-top: 0.68rem;
    margin-bottom: 0.13rem;
  }
  .center {
    height: 10.6rem;
    width: 7.5rem;
    background: url('~@/assets/images/activity/activity-template/modal-bg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    overflow: hidden;
  }
  .center-tip {
    background: url('~@/assets/images/activity/activity-template/modal-tip.png') no-repeat;
    background-size: 100% 100%;
    width: 3.64rem;
    height: 1.2rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff4518;
    line-height: 0.32rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .bottom {
    margin-top: auto;

    text-align: center;
    .bottom-btn {
      width: 6rem;
      height: 1rem;
      background: linear-gradient(180deg, #ffdb9b 0%, #ffbb70 100%);
      box-shadow: 0rem 0.07rem 0.3rem 0rem rgba(212, 24, 24, 0.5);
      border-radius: 0.5rem;
      font-size: 0.42rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #e5322e;
      line-height: 1rem;
      margin-bottom: 0.38rem;
      position: relative;
      .click_hand {
        width: 0.82rem;
        height: 0.94rem;
        position: absolute;
        right: 0rem;
        top: 0.3rem;
        z-index: 99;
        animation: handClick 0.6s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
      @keyframes handClick {
        0% {
          transform: translate(0, 0);
        }
        50% {
          transform: translate(0.2rem, 0.2rem);
        }
        100% {
          transform: translate(0, 0);
        }
      }
    }
    .bottom-desc1 {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.32rem;
      margin-bottom: 0.04rem;
    }
    .bottom-desc2 {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffe58e;
      line-height: 0.32rem;
      margin-bottom: 0.48rem;
    }
  }
  .icon {
    width: 0.68rem;
    height: 0.68rem;
    margin-top: 0.4rem;
  }
</style>
