<template>
  <div class="task" v-if="taskInfo.length > 0">
    <div class="task-title">任务列表</div>
    <div class="task-line"></div>
    <OneTask v-for="item in taskInfo" :key="item.id" :taskInfo="item" v-bind="$attrs"></OneTask>
  </div>
</template>
<script>
  import OneTask from './one-task.vue';
  export default {
    components: { OneTask },
    props: {
      task: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        pendantIcon: require('@/assets/images/activity/activity-template/pendant.png'),
        coinIcon: require('@/assets/images/activity/activity-template/coin.png'),
        medalIcon: require('@/assets/images/activity/activity-template/medal.png'),
        taskInfo: this.task.task,
      };
    },
    watch: {
      task: {
        handler() {
          this.taskInfo = this.task.task;
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>

<style lang="less" scoped>
  .task {
    width: 100%;
    background: #ffffff;
    border-radius: 0.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    overflow: hidden;
    & > div {
      margin: 0 0.3rem;
      width: auto;
    }
    .task-title {
      height: 0.8rem;
      font-size: 0.32rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.8rem;
    }
    .task-line {
      height: 0.02rem;
      background: #ebebeb;
    }
  }
</style>
