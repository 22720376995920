<template>
  <div class="fortune">
    <div class="fortune-box">
      <!-- 转盘奖品区 -->
      <Lottery2
        class="lottery"
        ref="lottery"
        :radius="141"
        :prizes="prize_config"
        :text-y="20"
        :img-y="28"
        :text-size="12"
        :img-size="50"
        @lotteryEnd="showReward"
      />
      <!-- 转盘边框 -->
      <img class="turntable" src="@/assets/images/fortune-wheel/turntable-new.png" />
      <!-- <div class="turntable">
			<lottie :options="marqueeOptions" />
			</div> -->
      <!-- 转盘中间抽奖按钮 -->

      <div class="lottery-btn" @click="handleLottery">
        <img class="click_hand" src="@/assets/images/fortune-wheel/hand.png" />
        <span v-if="+prize.used_coin == 1 && (remain_luck_draw_num == 0 || +prize.used_times == 2)">金币抽奖</span>
        <span v-else>还剩{{ remain_luck_draw_num }}次</span>
      </div>
    </div>

    <modal v-if="visiable"></modal>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from 'vuex';
  import modal from '../modal/modal.vue';
  export default {
    props: {
      prize: {
        type: Object,
        default: () => {},
      },
      name: {
        type: String,
        default: '',
      },
    },
    components: { modal },
    data() {
      return {
        show: false, // 宝箱提示文案显示控制
        rewardShow: false, // 奖励弹窗显示控制
        chestRewardShow: false,
        rewardType: 2, // 抽奖奖励模块布局；1：不翻倍布局；2：翻倍布局
        chestRewardType: 2, // 宝箱奖励模块布局；3：不翻倍布局；2：翻倍布局
        lotteryStatus: false, // 抽奖状态，true 为抽奖中， false 为未抽奖
        startCountDown: false, // 抽奖倒计时
        /* // 抽奖类型；
        // 'free': 免费抽奖
        // 'video': 视频抽奖
        // 'double': 翻倍抽奖
        // 'again': 再来一次抽奖
        // 'disabled': 不能抽奖
           'countdown': 倒计时中*/
        lotteryType: 'free',
        imgConfig: {
          1: 'http:\/\/static1.kaixinyf.cn\/img/lza63a94cab0f531258332675.png',
          2: 'http:\/\/static1.kaixinyf.cn\/img/lza63a94d4f7c2ae539185920.png',
          3: 'http:\/\/static1.kaixinyf.cn\/img/lza63a94d677fe43552985769.png',
          6: 'http:\/\/static1.kaixinyf.cn\/img/lza63f46641b6bf8432495400.png', // 会员
          7: 'http:\/\/static1.kaixinyf.cn\/img/lza63f4665e89485574562060.png', // 优惠券
          0: 'http:\/\/static1.kaixinyf.cn\/img/lza63a94d7b1b0de034047920.png',
        },
        remain_luck_draw_num: 0,
        prize_config: [],
        visiable: false,
      };
    },
    created() {
      // this.$sdkNew.register('ON_REWARD_VIDEO_PLAYED', this.videoEndCallback);
    },
    methods: {
      ...mapMutations(['updateTurnTable', 'updateTimeLimit']),
      ...mapActions(['registerVideoCallback']),
      ...mapMutations('activityPage/activityTemplate', ['setFortuneCanClick']),
      /**
       * @description: 抽奖按钮点击事件处理方法
       */

      handleLottery() {
        this.$track({
          page_title: '活动分享落地页',
          element_name: '开始抽奖',
        });
        let random = Math.floor(this.prize_config.length * Math.random());
        let prize = this.prize_config[random];
        this.$refs.lottery.handleLottery({ ...prize });
      },

      /**
       * @description: 节流函数
       * @param { Function} fn , { Array } params, { Number } wait
       */

      throttle(fn, wait, params) {
        let pre = null;
        const context = this;

        return function () {
          let now = Date.now();

          if (!pre || now - pre > wait) {
            fn.apply(context, params);
            pre = Date.now();
          }
        };
      },
      /**
       * @description: 抽奖结束展示恭喜获得弹窗和改变宝箱状态
       */

      showReward(winPrize) {
        this.visiable = true;
      },
    },

    computed: {
      ...mapGetters({
        reward: 'reward',
        turntable: 'turntable',
        video: 'video',
        progress: 'progress',
        rewardVideo: 'rewardVideo',
        chest: 'chest',
      }),
      ...mapGetters('activityPage/activityTemplate', ['fortuneCanClick', 'prizeNumber']),

      width() {
        return `${this.progress * 0.575}rem`;
      },
    },
    watch: {
      startCountDown(newVal, oldVal) {
        console.log(newVal, oldVal);
        if (newVal) {
          let timer = setInterval(() => {
            let { timeLimit } = this.turntable;

            if (timeLimit === 0 || timeLimit < 0) {
              clearInterval(timer);
              timer = null;
              this.startCountDown = false;
              return;
            }

            this.updateTimeLimit({ timeLimit: timeLimit - 1 });
          }, 1000);
        }
      },
      prizeNumber(newVal) {
        this.remain_luck_draw_num += newVal;
      },
      prize: {
        handler(newVal) {
          console.log(newVal, 'prize--');
          this.remain_luck_draw_num = newVal.remain_luck_draw_num;
          this.prize_config = [];
          newVal.prize.map((item, index) => {
            let data = {
              ...item,
              pic: 'https://static1.kaixinyf.cn/' + item.prize_img,
              // +item.prize_type == 8 && item.prize_img ? 'https://static1.kaixinyf.cn/' + item.prize_img : this.imgConfig[item.prize_type],
              desc: item.text_name,
              reward_type: item.prize_type,
              hit_draw_prize_id: item.id,
            };
            data.color = index % 2 == 0 ? '#E89BFD' : '#ffffff';
            data.gradient =
              index % 2 == 0
                ? [
                    {
                      color: '#f96941',
                      ratio: 0,
                    },
                    {
                      color: '#f96941',
                      ratio: 1,
                    },
                  ]
                : '#ffffff';
            this.prize_config.push(data);
          });
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>

<style lang="less" scoped>
  .fortune {
    width: 100%;
    height: 7.32rem;
    border-radius: 0.2rem;
    // margin-top: 0.1rem;
    margin-bottom: 0.2rem;
    background: url('~@/assets/images/fortune-wheel/bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .fortune-box {
    width: 100%;
    // background: #ffffff;

    // overflow: hidden;
    // height: 3.51rem;
    position: relative;
    .lottery-countdown {
      position: absolute;
      top: -0.44rem;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 10;
    }
  }
  .lottery .turntable,
  .lottery-btn,
  .lottery-tip,
  .head,
  .base,
  .chest,
  .chest-active,
  .outer,
  .ratio,
  .global-message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .turntable {
    margin-top: 0.4rem;
    width: 7rem;
    height: 7rem;
  }
  .lottery {
    z-index: 99;
  }
  .lottery-btn {
    width: 2.58rem;
    height: 2.58rem;
    background: url('~@/assets/images/fortune-wheel/middle-btn.png') center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.24rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff5224;
    line-height: 0.32rem;
    span {
      margin-top: 0.8rem;
    }
    .click_hand {
      width: 0.82rem;
      height: 0.94rem;
      position: absolute;
      right: 0.2rem;
      bottom: 0.2rem;
      z-index: 99;
      animation: handClick 0.6s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }
  .btn {
    width: 5.7rem;
    height: 1.1rem;
    background: #ff671f;
    border-radius: 0.56rem;

    font-size: 0.36rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 1.1rem;
    text-align: center;
    margin: 0 auto;
    margin-top: 0.24rem;
    position: relative;
    z-index: 999;
  }

  .btn1 {
    background: #ff671f;
  }
  .btn2 {
    background: #ffb71f;
  }
  .btn3 {
    background: #ff1f1f;
  }

  @keyframes handClick {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0.2rem, 0.2rem);
    }
    100% {
      transform: translate(0, 0);
    }
  }
</style>
