<template>
  <ul class="gallery_list_wrapper">
    <li class="gallery_item" @click="handleClickImg" v-for="(item, index) in displayGalleryList" :key="index">
      <img class="item_image" width="100%" height="100%" :src="item.cdn_url" v-lazy="item.cdn_url" alt="" />
      <div v-if="index + 1 === displayGalleryList.length" class="count_tag">
        <span>{{ galleryInfo.pic_num }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      galleryList: {
        type: Array,
        default: () => [],
      },
      galleryInfo: {
        type: Object,
        required: true,
      },
    },
    watch: {
      galleryList(val) {
        console.log(val);
      },
    },
    computed: {
      displayGalleryList() {
        return this.galleryList.length < 3 ? this.galleryList : this.galleryList.slice(0, 3);
      },
    },
    methods: {
      handleClickImg() {
        this.$emit('action', 'gallery');
      },
    },
  };
</script>

<style lang="less" scoped>
  .gallery_list_wrapper {
    display: flex;
    align-items: center;
    height: 1.36rem;
    .gallery_item {
      width: 2.4rem;
      height: 1.36rem;
      border: 1px solid rgba(221, 221, 221, 0.3);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      overflow: hidden;
      .item_image {
        width: 100%;
        height: auto;
        // object-fit: cover;
      }
      .count_tag {
        position: absolute;
        right: 0.08rem;
        bottom: 0.08rem;
        padding: 0.12rem;
        height: 0.36rem;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.75);
        border-radius: 0.08rem;
        span {
          height: 0.24rem;
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.24rem;
        }
      }
    }
    & li:not(:first-child) {
      margin-left: 0.08rem;
    }
    & li:first-of-type {
      border-radius: 0.12rem 0 0 0.12rem;
    }
    & li:last-of-type {
      border-radius: 0 0.12rem 0.12rem 0;
    }
  }
</style>
