<template>
  <div class="rank-box">
    <van-list
      v-model="loading"
      :finished="finish"
      loadingText="正在加载..."
      finished-text="暂不显示剩余排名哦～"
      :immediate-check="false"
      @load="onLoad"
    >
      <first-rank v-if="rankList[0]" :info="rankList[0]"></first-rank>
      <rank-item v-for="item in rankList" :info="item" :key="item.rank"></rank-item>
    </van-list>
  </div>
</template>

<script>
  import FirstRank from './components/FirstRank';
  import RankItem from './components/RankItem';
  import API from '@/api';
  import { mapGetters } from 'vuex';

  export default {
    components: { FirstRank, RankItem },
    data() {
      return {
        lastId: 0,
        rankList: [],
        loading: false,
        finish: false,
        pageTitle: '投稿排行榜',
      };
    },
    computed: {
      ...mapGetters('activity/activityTemplate', ['playListId']),
    },
    created() {
      this.getRankList();
    },
    methods: {
      async getRankList() {
        try {
          this.loading = true;
          const res = await this.$http.TaskPost(API.GET_ACTIVITY_RANK, {
            playlist_id: this.playListId,
            last_id: this.lastId,
          });

          this.rankList = [...this.rankList, ...res.user_list];
          this.lastId = res.last_id;
          this.finish = !!res.is_end;
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      },
      onLoad() {
        if (this.finish) return;

        setTimeout(this.getRankList, 500);
      },
    },
  };
</script>

<style lang="less" scoped>
  .rank-box {
    padding: 0 0.3rem;
  }
</style>
