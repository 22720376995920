// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../assets/images/activity/activity-template/rank1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../../assets/images/activity/activity-template/rank2.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../../../assets/images/activity/activity-template/rank3.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".rank-item-box[data-v-fb2b508e]{box-sizing:border-box;padding:.32rem .1rem}.avatar-box[data-v-fb2b508e],.rank-item-box[data-v-fb2b508e]{display:flex;align-items:center}.avatar-box[data-v-fb2b508e]{position:relative;margin:0 .24rem;flex-direction:column}.avatar[data-v-fb2b508e]{width:.66rem;height:.66rem;border-radius:.33rem;background-color:#bbb}.avatar[data-v-fb2b508e],.follow[data-v-fb2b508e]{display:inline-block}.follow[data-v-fb2b508e]{width:1.32rem;height:.52rem;margin-right:-.68rem;margin-bottom:-.5rem;background:linear-gradient(135deg,#ffb80c,#ff9100);border-radius:.38rem;border:2px solid #fff;font-size:.4rem;line-height:.4rem;color:#fff;font-weight:700;transform:scale(.5) translateY(-.1rem);transform-origin:0 0;text-align:center}.tip[data-v-fb2b508e]{margin-left:auto}.index[data-v-fb2b508e]{display:inline-block;width:.5rem;height:.51rem;font-size:.32rem;line-height:.51rem;color:#fff;font-weight:700;text-align:center;white-space:nowrap}.nickname[data-v-fb2b508e]{color:#1a1a1a;font-weight:700}.nickname[data-v-fb2b508e],.tip[data-v-fb2b508e]{font-size:.28rem;line-height:.28rem}.tip[data-v-fb2b508e]{color:#ff7200;font-weight:500}.rank1[data-v-fb2b508e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.rank1[data-v-fb2b508e],.rank2[data-v-fb2b508e]{background-size:cover}.rank2[data-v-fb2b508e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.rank3[data-v-fb2b508e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:cover}.followed[data-v-fb2b508e]{background:#fff;border:.5px solid #ff9602}.followed .icon[data-v-fb2b508e]{width:.44rem}", ""]);
// Exports
module.exports = exports;
