<template>
  <div class="first-rank-box">
    <h3 class="nickname">{{ info.nickname }}</h3>
    <p class="tip">占领排行榜封面</p>
    <img class="avatar" :src="info.avatar_small" @click="jumpToPerson" />
  </div>
</template>

<script>
  export default {
    props: {
      info: {
        type: Object,
        default: () => {
          return {
            rank: 1,
          };
        },
      },
    },
    methods: {
      jumpToPerson() {
        // this.$sdkNew.appCall(
        // 	'OPEN_PAGE_WITH_JSON',
        // 	JSON.stringify({
        // 		url: `kuaiyin://profile?uid=${this.info.user_id}`,
        // 	})
        // );
      },
    },
  };
</script>

<style lang="less" scoped>
  .first-rank-box {
    box-sizing: border-box;
    position: relative;
    height: 2.16rem;
    padding-top: 1.22rem;
    background-image: url('../../../../../../assets/images/activity/activity-template/first-rank-bg.png');
    background-size: cover;
  }
  .nickname {
    margin: 0 auto 0.14rem 0.36rem;
    font-size: 0.3rem;
    line-height: 0.3rem;
    color: #ffffff;
    font-weight: bold;
  }
  .tip {
    margin-left: 0.18rem;
    font-size: 0.24rem;
    line-height: 0.24rem;
    color: #ffffff;
    font-weight: 500;
  }
  .avatar {
    display: inline-block;
    width: 1.26rem;
    height: 1.26rem;
    position: absolute;
    top: 0.42rem;
    right: 0.54rem;
    border-radius: 0.75rem;
    border: 1px solid #fff;
    background-color: #d8cfd2;
  }
</style>
