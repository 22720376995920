<template>
  <div class="avatar_wrapper">
    <div class="bg_cd" :class="{ activited: activited }"></div>
    <div
      class="avatar_content"
      v-lazy-container="{ selector: 'img', error: '@/assets/images/activity/activity-template/default.png' }"
    >
      <img
        class="avatar_img"
        :class="{ activited: activited }"
        :data-src="avatar_img"
        :data-loading="defaultIcon"
        :data-error="defaultIcon"
      />
      <div v-if="activited" class="avatar_overlay"></div>
      <PlayingIconAnimation v-if="activited" class="playing_icon_animation" :playing="playing"></PlayingIconAnimation>
      <img v-if="!activited" class="play_icon" :src="playIcon" :data-src="playIcon" alt="" />
    </div>
  </div>
</template>

<script>
  const playIcon = require('@/assets/images/activity/activity-template/play_icon.png');
  const defaultIcon = require('@/assets/images/activity/activity-template/default.png');

  import PlayingIconAnimation from './PlayingIcon';
  export default {
    components: {
      PlayingIconAnimation,
    },
    props: {
      avatar_img: {
        type: String,
        default: '',
        required: true,
      },
      avatar_pendant: {
        type: String,
        default: '',
      },
      activited: {
        type: Boolean,
        default: false,
      },
      playing: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        playIcon: playIcon,
        defaultIcon: defaultIcon,
      };
    },
  };
</script>

<style lang="less" scoped>
  .avatar_wrapper {
    position: relative;
    width: 1.16rem;
    height: 1rem;
    margin-right: 0.12rem;
    flex-shrink: 0;
    .bg_cd {
      width: 1rem;
      height: 1rem;
      background: #f9f9f9;
      border-radius: 50%;
      position: absolute;
      top: 0.02rem;
      left: 0.16rem;
      transition: background 0.3s;
      &.activited {
        background: #333333;
      }
    }
    .avatar_content {
      position: absolute;
      top: 0;
      z-index: 101;
      width: 1.04rem;
      height: 1.04rem;
      border-radius: 0.12rem;
      overflow: hidden;
      .avatar_img {
        position: absolute;
        top: 0;
        height: 1.04rem;
        right: 0;
        left: 0;
        padding: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        &.activited {
          filter: blur(2px);
          transform: scale(1.1);
        }
      }
      .avatar_overlay {
        position: absolute;
        z-index: 103;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2);
      }
      .playing_icon_animation {
        position: absolute;
        z-index: 102;
      }
      .play_icon {
        position: absolute;
        z-index: 102;
        width: 0.28rem;
        height: 0.28rem;
        right: 0.08rem;
        bottom: 0.08rem;
      }
    }
  }
</style>
