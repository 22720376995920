<template>
  <div class="gallery-box" @click="handleClick">
    <img class="gallery-cover" :src="info.pic" alt="活动封面" />
    <h3 v-if="info.pic_title" class="title">{{ info.pic_title }}</h3>
    <p v-if="info.pic_desc" class="subtitle">{{ info.pic_desc }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      info: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    methods: {
      handleClick() {
        this.$emit('click');
      },
    },
  };
</script>

<style lang="less" scoped>
  .gallery-box {
    margin-bottom: 0.24rem;
    border-radius: 0.12rem;
    // background-color: #ffffff;
  }
  .gallery-cover {
    display: inline-block;
    width: 100%;
    // min-height: 0.8rem;
    border-radius: 0.12rem 0.12rem 0 0;
    vertical-align: bottom;
  }
  .title {
    max-width: 5.2rem;
    margin: 0.24rem 0 -0.08rem 0.3rem;
    padding-bottom: 0.24rem;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: bold;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .subtitle {
    max-width: 6.3rem;
    margin-left: 0.3rem;
    padding-bottom: 0.24rem;
    font-size: 0.24rem;
    line-height: 0.24rem;
    color: #a6a6a6;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
