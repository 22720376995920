<template>
  <main class="module-box">
    <header class="title-box">
      <img class="title-left" src="https://static1.kaixinyf.cn/img/lza62ff63f7b8910104631010.png" />
      <span class="title">{{ title }}</span>
      <img class="title-left" src="https://static1.kaixinyf.cn//img/lza62ff641dbddcd996261086.png" />
    </header>
    <slot></slot>
  </main>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: '标题',
      },
    },
  };
</script>

<style lang="less" scoped>
  .module-box {
    border-radius: 0.24rem;
    // background-color: #fff;
    margin-bottom: 0.36rem;
  }
  .title-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    text-align: center;
    img {
      display: inline-block;
      vertical-align: middle;
      margin-top: -0.04rem;
    }
  }
  .title-left {
    width: 0.6rem;
  }
  .title {
    position: relative;
    margin: 0 0.41rem;
    z-index: 2;
    white-space: nowrap;
    // .fontSet(0.14rem; 0.21rem; #1a1a1a; PingFangSC-Semibold, PingFang SC; bold);
    color: #1a1a1a;
    font-size: 0.28rem;
    font-weight: bold;
  }
</style>
