<template>
  <div class="rank-item-box">
    <span v-if="info.rank < 4" class="index" :class="'rank' + info.rank"></span>
    <span v-else class="index">{{ info.rank >= 10 ? info.rank : `0${info.rank}` }}</span>
    <p class="avatar-box">
      <img class="avatar" :src="info.avatar_small" @click="jumpToPerson" />
      <template>
        <span v-if="!follow" class="follow" @click="followUser">关注</span>
        <span v-else class="follow followed">
          <img class="icon" src="@/assets/images/activity/activity-template/followed-icon.png" />
        </span>
      </template>
    </p>
    <h3 class="nickname">{{ info.nickname }}</h3>
    <span class="tip">{{ info.music_num }}投稿量</span>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: {
      info: {
        type: Object,
        default: () => {
          return {
            rank: 1,
          };
        },
      },
    },
    data() {
      return {
        follow: false,
      };
    },
    computed: {
      ...mapGetters('activity/activityTemplate', ['activityName']),
    },
    created() {
      this.follow = !!this.info.follow;
    },
    methods: {
      async followUser() {
        try {
          this.$root.trackClick({
            page_title: this.activityName,
            element_name: '排行榜-关注用户',
          });

          //   await this.$clientPost(this.$api.FOLLOW_USER_API, { uid: this.info.user_id });
          this.follow = true;
          this.$toast('关注成功');
        } catch (e) {
          console.log(e);
          this.$toast('关注失败');
        }
      },
      jumpToPerson() {
        // this.$root.trackClick({
        //   page_title: this.activityName,
        //   element_name: '排行榜-点击用户头像',
        // });
        // this.$sdkNew.appCall(
        //   'OPEN_PAGE_WITH_JSON',
        //   JSON.stringify({
        //     url: `kuaiyin://profile?uid=${this.info.user_id}`,
        //   })
        // );
      },
    },
  };
</script>

<style lang="less" scoped>
  .rank-item-box {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0.32rem 0.1rem;
  }
  .avatar-box {
    position: relative;
    margin: 0 0.24rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .avatar {
    display: inline-block;
    width: 0.66rem;
    height: 0.66rem;
    border-radius: 0.33rem;
    background-color: #bbbbbb;
  }
  .follow {
    display: inline-block;
    width: 1.32rem;
    height: 0.52rem;
    margin-right: -0.68rem;
    margin-bottom: -0.5rem;
    background: linear-gradient(135deg, #ffb80c 0%, #ff9100 100%);
    border-radius: 0.38rem;
    border: 2px solid #ffffff;
    font-size: 0.4rem;
    line-height: 0.4rem;
    color: #ffffff;
    font-weight: bold;
    transform: scale(0.5) translateY(-0.1rem);
    transform-origin: 0 0;
    text-align: center;
  }
  .tip {
    margin-left: auto;
  }
  .index {
    display: inline-block;
    width: 0.5rem;
    height: 0.51rem;
    font-size: 0.32rem;
    line-height: 0.51rem;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
  }
  .nickname {
    font-size: 0.28rem;
    line-height: 0.28rem;
    color: #1a1a1a;
    font-weight: bold;
  }
  .tip {
    font-size: 0.28rem;
    line-height: 0.28rem;
    color: #ff7200;
    font-weight: 500;
  }
  .rank1 {
    background-image: url('../../../../../../assets/images/activity/activity-template/rank1.png');
    background-size: cover;
  }
  .rank2 {
    background-image: url('../../../../../../assets/images/activity/activity-template/rank2.png');
    background-size: cover;
  }
  .rank3 {
    background-image: url('../../../../../../assets/images/activity/activity-template/rank3.png');
    background-size: cover;
  }
  .followed {
    background: #fff;
    border: 0.5px solid #ff9602;
    .icon {
      width: 0.44rem;
    }
  }
</style>
